<template>
	<Utils ref='Utils' />
	<div class="card-gray" style="width: 100%;" align="center">
		<div class="card grid col-12 lg:col-12" style='background-color: #ffffff;justify-content: center;margin-top: 0.625rem;'
			align="center">
			<img src="images/banner_pic_4.png"
				style="margin-bottom:-2.5rem;margin-top: -1.25rem; max-width:100%;max-height: 150px;" />
			<!-- <h3>使用萤火虫</h3> -->
		</div>

		<div class="grid col-12 lg:col-12" style="justify-content: space-between;">
			<div class="card grid col-12 lg:col-9" style="margin-top: 10px;">
				<div class="card-gray p-inputgroup">
					<Button icon="pi pi-search" title='查单词' label="Search" @click="searchWord" />
					<InputText id="search_input" style="width: 100%;" v-model="select_word"
						v-on:keyup.enter="searchWord" type="text" placeholder="查单词<(￣︶￣)↗[GO!]" />
					<span class="p-inputgroup-addon"> <i class="pi pi-times" style="opacity: 0.7;"
							@click.stop="InitialWords" title='清空输入框' /></span>
				</div>
				<div class="grid col-12"
					style="margin-top: 0.625rem;height:500px;overflow: auto;justify-content: space-around;">
					<div class="col-12 lg:col-6">
						<div class='card'>
							<div class="translation-item field"
								style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
								<div class='col title-item'>原文</div>
								<div class='col title-item'>释义</div>
								<div class='col title-item'>来源专业</div>
							</div>
							<div class="card-gray word-item translation-item"
								style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
								v-for="(item, j) of words.tl" :key="j">
								<div class='col title-item'>{{words.or}}</div>
								<div class='col title-item'>{{item.w}}</div>
								<div class='col title-item'>
									<div style="display: flex;flex-direction: column;">
										<span style="margin-top: auto;align-items: center;text-align: left;" v-for="(mjname, m) of item.mjid_from" :key="m">{{mjname.fr}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 lg:col-6">
						<div class='card' style="margin-bottom:0px;">
							<div class="translation-item field"
								style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
								<div class='col title-item'>相似词汇</div>
								<div class='col title-item'>释义</div>
								<div class='col title-item'>来源专业</div>
							</div>
							<div class='field' style="margin-bottom:0px;">
								<div title='点击后查询当前词汇' class="card-gray word-item translation-item"
									style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
									v-for="(item, j) of similar_words" :key="j">
									<div class='col title-item' @click="searchWordItem(item)">{{item.w}}</div>
									<div class='col title-item'>{{item.tr}}</div>
									<div class='col title-item'>
										<div style="display: flex;flex-direction: column;">
											<span style="margin-top: auto;align-items: center;text-align: left;" v-for="(mjname, m) of item.mjid_from" :key="m">{{mjname.fr}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid card col-12 lg:col-3" style="margin-top: 10px;">
				<!-- <h3>词典</h3> -->
				<div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
					<Button title='私有化心仪的词典' icon="pi pi-shopping-cart" @click="toggle_wechat_pay" label="购买专业词典"
						class="p-button-success mr-2 mb-2"></Button>
					<OverlayPanel ref="wechat_pay" appendTo="body" :showCloseIcon="true">
						<div style="width: 400px;" align="center">
							<h5>添加客服微信付款并获取词典</h5>
							<!-- <Button label="图片PDF版 ￥20/本" style="width: 350px;" class="p-button-info mb-3" /> -->
							<Button label="Word版 ￥30/本" style="width: 350px;" class="p-button-info mb-3" />
							<img src="images/helper.jpg" style='width: 350px;' alt="" />
						</div>
					</OverlayPanel>
					<Button title='contact me with WhatsApp' icon="pi pi-whatsapp" label="WhatsApp" @click="toggleWhatsApp"
						class="p-button-rounded p-button-success mr-2 mb-2"></Button>
					<OverlayPanel ref="whats_app" appendTo="body" :showCloseIcon="true">
						<img src="images/my_whatsapp.jpg" style="width: 300px;" />
					</OverlayPanel>
				</div>
				<div class='card-gray col-12 lg:col-12' style="margin-bottom:0px;height: 500px;overflow: auto;">
					<div class="translation-item field"
						style="font-weight: 700;padding-bottom: 5px;background-color:#f8f9fa;">
						<div class='col title-item'>专业</div>
						<div class='col title-item'>词汇数量</div>
					</div>
					<div v-if='major_words_number.length>0' class='field' style="margin-bottom:0px;">
						<div class="word-item translation-item"
							style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"
							v-for="(item, j) of major_words_number" :key="j">
							<div class='col title-item'><i style="margin-right: 10px;">{{j+1}}</i>{{item.major_name}}
							</div>
							<div class='col title-item'>{{item.words_count}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="card" style='background-color: #ffffff;justify-content: center;margin-top: 10px;margin-bottom: 0px;'
			align="center">
			<ins class="adsbygoogle" style="display:block" data-ad-format="autorelaxed"
				data-ad-client="ca-pub-8147879111198627" data-ad-slot="1184874296"></ins>
		</div>
	</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	import ReadFileService from '../../service/ReadFileService.js';
	import Utils from '../Utils.vue';
	export default {
		name: '',
		components: {
			'Utils': Utils,
		},
		data() {
			return {
				select_word: '',
				trans_style: {
					"overflow": "auto",
					"height": "auto",
				},
				major_words_number: [],
				words: {},
				similar_words: [],
				no_words: false,
			}
		},
		networkService: null,
		created() {
			//实例化NetWorkService对象
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			window.addAds();
			this.ReadFileService.getMajorsDictNumber().then(data => {
				// console.log('读取到数据',data);
				this.major_words_number = data;
				// this.item_class_list=[];
				// // this.saveClassToLocalstorage();
				// var that=this;
				// setTimeout(() => {
				// 	that.item_class_list=data.class_list;
				// 	that.saveClassToLocalstorage();
				// }, 1000)
			});
		},
		methods: {
			toggle_wechat_pay(event) {
				this.$refs.wechat_pay.toggle(event);
			},
			toggleWhatsApp(event) {
				this.$refs.whats_app.toggle(event);
			},
			async searchWord() {
				var resp = await this.$refs.Utils.searchDictWord(this.select_word);
				if (resp.code == 200) {
					this.words = resp.data.words;
					this.similar_words = resp.data.similar_words;
				}
			},
			InitialWords() {
				this.select_word = '';
			},
			//查询类似单词
			searchWordItem(item) {
				this.select_word = item.w;
				this.searchWord();
			},
		},
	}
</script>

<style scoped lang="scss">
	::v-deep(.p-multiselect) {
		min-width: 15rem;
	}

	.multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}

	::v-deep(.multiselect-custom .p-multiselect-label) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}

	.multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}

	.multiselect-custom .country-item.country-item-value span.flag {
		width: 17px;
	}

	.country-item {
		display: flex;
		align-items: center;
	}

	.country-item span.flag {
		width: 18px;
		height: 12px;
		margin-right: .5rem;
	}

	.multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}

	.word-item {}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.translation-item {
		display: flex;
		flex-direction: row;
		// align-items: center;
		// margin: auto;
		// background-color: red;
	}

	// .translation-item div {
	// 	// background-color: yellow;
	// 	float: left;
	// }	

	.title-item {
		display: flex;
		// padding: 0px;
		// flex-direction: row;
		align-items: center;
		// justify-content: space-between;
		float: left;
		text-align: left;
	}
</style>
