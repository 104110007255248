import axios from 'axios'

export default class MajorService {

	getGISClass() {
		return axios.get('data/20220614标注用要素代码定义.json').then(res => res.data.data);
	}
	
	getMajorsDictNumber() {
		return axios.get('data/majors_dict_number.json').then(res => res.data.data);
	}

	getHelperText() {
		return axios.get('data/20220628帮助文档.json').then(res => res.data);
	}
}
