import axios from 'axios';
// var base_url = "http://101.34.116.86:8529/api";
var base_url = "/api";
axios.interceptors.request.use(config => {
	// config.headers.common = {
	//   'Authorization':localStorage.getItem('Authorization')==undefined?'':localStorage.getItem('Authorization')
	// }
	config.timeout = 60000;
	return config
})

export default class NetWorkService {
	LL(a) {
		// var k = "";
		var b = 406532644;
		var b1 = 329311072;

		var jd = ".";
		var $b = "+-a^+6";
		var Zb = "+-3^+b+-f";

		for (var e = [], f = 0, g = 0; g < a.length; g++) {
			var m = a.charCodeAt(g);
			128 > m ? e[f++] = m : (2048 > m ? e[f++] = m >> 6 | 192 : (55296 == (m & 64512) && g + 1 < a.length &&
					56320 == (a.charCodeAt(g + 1) & 64512) ? (m = 65536 + ((m & 1023) << 10) + (a.charCodeAt(++
								g) &
							1023),
						e[f++] = m >> 18 | 240,
						e[f++] = m >> 12 & 63 | 128) : e[f++] = m >> 12 | 224,
					e[f++] = m >> 6 & 63 | 128),
				e[f++] = m & 63 | 128)
		}
		a = b;
		for (f = 0; f < e.length; f++) a += e[f],
			a = this.RL(a, $b);
		a = this.RL(a, Zb);
		a ^= b1 || 0;
		0 > a && (a = (a & 2147483647) + 2147483648);
		a %= 1E6;
		return a.toString() + jd + (a ^ b)
	}

	RL(a, b) {
		var t = "a";
		var Yb = "+";
		for (var c = 0; c < b.length - 2; c += 3) {
			var d = b.charAt(c + 2);
			d = d >= t ? d.charCodeAt(0) - 87 : Number(d);
			d = b.charAt(c + 1) == Yb ? a >>> d : a << d;
			a = b.charAt(c) == Yb ? a + d & 42377295 : a ^ d
		}
		return a
	}

	getIP() {
		var posturl = 'http://pv.sohu.com/cityjson?ie=utf-8';
		return axios.get(posturl).then(res => {
			// console.log('当前IP',res.data);
			return res.data.cip;
		});
	}

	getTimeStamp() {
		return new Date().getTime().toString();
	}

	//post方法
	postService(url, data) {
		//每次请求的时候，设置处理为1，处理完成后设置为0
		// this.getIP()
		var posturl = base_url + url;
		var time_stamp = this.getTimeStamp();
		var sign = this.LL(time_stamp);
		// console.log('sign',sign);
		var this_header = {
			'Authorization': localStorage.getItem('Authorization') == undefined ? '' : localStorage.getItem(
				'Authorization'),
			'TimeStamp': time_stamp,
			'Sign': sign,
		}


		return axios.post(posturl, data, {
			'headers': this_header
		}).then(res => {
			if (res.data.code == 429) {
				alert('服务器繁忙，请稍后再试！')
				return {}
			}
			return res.data;
		}).catch(function(err) {
			// alert('操作失败，请稍后再试！');
			var current_second = new Date().getTime();
			Date.prototype.toLocaleString = function() {
				return this.getFullYear() + "-" + (this.getMonth() + 1) + "-" + this.getDate() + '  ' +
					this.getHours() + ":" + this.getMinutes() + ":" + this.getSeconds();
			};
			// 按重写的自定义格式，格式化日期
			var dateTime = new Date(current_second).toLocaleString();
			var error_msg = "[" + dateTime + "]\r\n" + posturl + "错误：" + err +
				"\r\n已邮件通知管理员该错误，获取更多帮助请联系客服微信：LibertyNLP_01"
			alert(error_msg);

			var error_data = new URLSearchParams();
			error_data.append("error_msg", "[" + dateTime + "]\r\n" + posturl + "错误(来自FFDict)：" + err);
			var error_url = '/error-api/'
			axios.post(error_url, error_data, {
				'headers': this_header
			})

			var resp = {
				'code': -1,
				'data': {},
			}
			return resp;
		});
	}
	//测试登录时候的调试语法请
	checkScanService(data) {
		var posturl = 'http://july.ccuz.net/check-scan';
		return axios.post(posturl, data).then(res => {
			return res.data;
		});
	}
}
